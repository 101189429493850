import React from "react";
import Layout from "../components/layout";
import { Card, Row, Col, Image, ListGroup, ListGroupItem } from "react-bootstrap";
import HipoImg from "../images/construction.jpg"
import Seo from "../components/seo";

const Credit1 = () => (
    <Layout>
        <Seo title="Crédito hipotecario" description="Describe crédito hipotecario en CoopGestión" />
        <Row>
            <Col>
                <div className="jumbotron bg-secondary text-white">
                    <h1 className="display-4 jumbotron-title">CRÉDITO HIPOTECARIO</h1>
                    <p className="lead">
                        Crédito de acuerdo al tamaño de tu necesidad.
                    </p>
                </div>
            </Col>
        </Row>
        <Row className="my-3">
            <Col sm className="m-auto py-1 text-center">
                <Image src={HipoImg} alt="Crédito hipotecario" fluid />
            </Col>
            <Col sm className="m-auto">
                <Card>
                    <Card.Header>Crédito Hipotecario</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            Desde 10.000 USD en adelante.
                        </Card.Text>
                        <Card.Title>
                            Requisitos
                        </Card.Title>
                        <ListGroup variant="flush">
                            <ListGroupItem>&#x2022;  Ser socio</ListGroupItem>
                            <ListGroupItem>&#x2022;  Solicitud de crédito</ListGroupItem>
                            <ListGroupItem>&#x2022;  Copia de cédula de identidad</ListGroupItem>
                            <ListGroupItem>&#x2022;  Copia de papeleta de votación</ListGroupItem>
                            <ListGroupItem>&#x2022;  Rol de pagos o certificado de ingresos</ListGroupItem>
                            <ListGroupItem>&#x2022;  Copia de impuesto predial</ListGroupItem>
                            <ListGroupItem>&#x2022;  Planilla de servicio básico</ListGroupItem>
                        </ListGroup>
                        {/* <Card.Text>
                            <ul>
                                <li>Ser socio</li>
                                <li>Solicitud de crédito</li>
                                <li>Copia de cédula de identidad</li>
                                <li>Copia de papeleta de votación</li>
                                <li>Rol de pagos o certificado de ingresos</li>
                                <li>Copia de impuesto predial</li>
                                <li>Planilla de servicio básico</li>
                            </ul>
                        </Card.Text> */}
                    </Card.Body>
                </Card>
            </Col>
        </Row>

    </Layout>
);

export default Credit1;